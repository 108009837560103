<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <div class="filter-card">
          <form
            @keydown.enter="
              (page = 0), customerType === 'common' ? getCommonCustomer() : getUncommonCustomer()
            "
          >
            <div class="filter-field">
              <label>客戶查詢</label>
              <input type="text" placeholder="請輸入關鍵字" v-model.trim="keyword" />
            </div>
            <button
              type="button"
              class="btn btn-primary btn-sm ml-1"
              @click="
                (page = 0), customerType === 'common' ? getCommonCustomer() : getUncommonCustomer()
              "
            >
              查詢
            </button>
            <router-link
              v-if="isQuoteUser === 'true'"
              class=" btn btn-warning btn-sm ml-2"
              :to="{ path: 'customer-quote' }"
            >
              報價
            </router-link>

            <button type="button" class="btn btn-primary btn-sm logout-btn" @click="logout">
              登出
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="customer-tab">
        <a
          type="button"
          :class="{ active: customerType === 'common' }"
          @click.prevent="(customerType = 'common'), (page = 0)"
        >
          常用客戶
        </a>
        <a
          type="button"
          :class="{ active: customerType === 'uncommon' }"
          @click.prevent="(customerType = 'uncommon'), (page = 0)"
        >
          一般客戶
        </a>
      </div>
      <div class="table-wrap">
        <!-- 常用客戶 -->
        <el-table v-show="customerType === 'common'" :data="commonCustomer" :header-cell-style="{fontWeight: 600}" style="width: 100%">
          <el-table-column align="center">
            <template>
              <img width="50" src="~@/assets/img/custom_head.png" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="cusT_SNM" align="center"> </el-table-column>
          <el-table-column prop="cusT_NOX" align="center" />
          <el-table-column align="center">
            <template slot-scope="scope">
              <span title="重設密碼">
                <eva-icon
                  name="lock-outline"
                  style="cursor: pointer;"
                  data-toggle="modal"
                  data-target="#settingPasswordModal"
                  @click="(customerName = scope.row.cusT_SNM), (customerID = scope.row.cusT_ID)"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="
                  updateCustomerType({ changeType: 'uncommon', customerID: scope.row.cusT_ID })
                "
              >
                移除常用
              </button>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="selectCustomer(scope.row)"
              >
                選擇
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>

        <!-- 一般客戶 -->
        <el-table v-show="customerType === 'uncommon'" :data="uncommonCustomer" style="width: 100%">
          <el-table-column align="center">
            <template>
              <img width="50" src="~@/assets/img/custom_head.png" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="cusT_SNM" align="center" />
          <el-table-column prop="cusT_NOX" align="center" />
          <el-table-column align="center">
            <template slot-scope="scope">
              <span title="重設密碼">
                <eva-icon
                  name="lock-outline"
                  style="cursor: pointer;"
                  data-toggle="modal"
                  data-target="#settingPasswordModal"
                  @click="(customerName = scope.row.cusT_SNM), (customerID = scope.row.cusT_ID)"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                @click="updateCustomerType({ changeType: 'common', customerID: scope.row.cusT_ID })"
              >
                加入常用
              </button>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="selectCustomer(scope.row)"
              >
                選擇
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>

        <!-- 分頁 -->
        <el-pagination
          v-show="count > 0"
          layout="prev, pager, next"
          :total="count"
          @current-change="changePage"
        />
      </div>

      <!-- 重設密碼 Modal -->
      <div
        class="modal fade"
        id="settingPasswordModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="text-center">重新設定密碼</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="text-center">{{ customerName }}</p>
              <div class="field">
                <label>
                  <input
                    class="text-center"
                    type="text"
                    placeholder="請輸入手機號碼"
                    v-model.trim="phone"
                  />
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                @click="(phone = ''), (customerID = '')"
              >
                取消
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-dismiss="modal"
                @click="resetPassword"
              >
                確認
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* global $ */
import { mapActions } from "vuex";
import {
  apiGetCommonCustomer,
  apiGetUncommonCustomer,
  apiCustomerToCommon,
  apiCustomerToUncommon,
  apiResetPassword
} from "@/apis/customer";

export default {
  name: "SelectCustomer",
  data() {
    return {
      // 客戶類型
      customerType: "uncommon",

      // 是否顯示客戶報價
      isQuoteUser: "",

      // 關鍵字
      keyword: "",
      // 常用客戶
      commonCustomer: [],
      // 一般客戶
      uncommonCustomer: [],

      // 客戶總筆數
      count: 0,

      // 客戶ID
      customerID: "",
      // 客戶名稱
      customerName: "",
      // 手機號碼(用來重新設定密碼)
      phone: "",

      // 當前分頁
      page: 0
    };
  },

  methods: {
    // 選擇客戶
    selectCustomer(customer) {
      localStorage.setItem("CUSTOMER_INFO", JSON.stringify(customer));
      this.$store.dispatch("getCartProductQuantity", 0);
      this.$router.push({ path: "frequently-bought" });
    },

    // 將客戶 加入 or 移除常用
    updateCustomerType({ changeType, customerID }) {
      switch (changeType) {
        case "common":
          this.updateLoading(true);
          apiCustomerToCommon({
            salE_MAN: localStorage.USER_GUID,
            cusT_ID: customerID
          })
            .then(response => {
              if (response.status === 200) {
                this.updateLoading(false);
                this.$notify({
                  title: "成功",
                  message: "已加入常用",
                  type: "success",
                  duration: 2500
                });
                this.count = 0;
                this.page = 0;
                this.getUncommonCustomer();
              }
            })
            .catch(() => {
              this.updateLoading(false);
            });
          break;
        case "uncommon":
          this.updateLoading(true);
          apiCustomerToUncommon({
            salE_MAN: localStorage.USER_GUID,
            cusT_ID: customerID
          })
            .then(response => {
              if (response.status === 200) {
                this.updateLoading(false);
                this.$notify({
                  title: "成功",
                  message: "已移除常用",
                  type: "success",
                  duration: 2500
                });
                this.count = 0;
                this.page = 0;
                this.getCommonCustomer();
              }
            })
            .catch(() => {
              this.updateLoading(false);
            });
          break;
        default:
          return;
      }
    },

    // 重新設定密碼
    resetPassword() {
      this.updateLoading(true);
      apiResetPassword({
        cusT_ID: this.customerID,
        phone: this.phone
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.$notify({
              title: "成功",
              message: "設定成功",
              type: "success",
              duration: 2500
            });
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.customerType === "common" ? this.getCommonCustomer() : this.getUncommonCustomer();
    },

    // 取得常用客戶
    getCommonCustomer() {
      this.updateLoading(true);
      apiGetCommonCustomer({
        salE_MAN: localStorage.USER_GUID,
        keyword: this.keyword,
        pageIndex: this.page
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            if (response.data) {
              this.count = response.data.count;
              this.commonCustomer = response.data.datas.map(item => {
                return {
                  cusT_ID: item.cusT_ID, // 客戶ID
                  cusT_NOX: item.cusT_NOX, // 客戶編號
                  cusT_SNM: item.cusT_SNM, // 客戶名稱
                  stoR_CDX: item.stoR_CDX, // 門市代號
                  weeK_DAYS: item.weeK_DAYS, // 可送貨日
                  emlX_ADR: item.emlX_ADR, // Email
                  preX_DAY: item.preX_DAY, // 可預下幾天單
                  limiT_TIME: item.limiT_TIME, // 結單時間
                  restdaY_SEND: item.restdaY_SEND, // 是否可下假日單
                  arxX_DDX: item.arxX_DDX, // 結帳日
                  weB_URL: item.weB_URL // 教學影片
                };
              });
            } else {
              this.count = 0;
              this.commonCustomer = [];
            }
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 取得一般客戶
    getUncommonCustomer() {
      this.updateLoading(true);
      apiGetUncommonCustomer({
        salE_MAN: localStorage.USER_GUID,
        keyword: this.keyword,
        pageIndex: this.page
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            if (response.data) {
              this.count = response.data.count;
              this.uncommonCustomer = response.data.datas.map(item => {
                return {
                  cusT_ID: item.cusT_ID, // 客戶ID
                  cusT_NOX: item.cusT_NOX, // 客戶編號
                  cusT_SNM: item.cusT_SNM, // 客戶名稱
                  stoR_CDX: item.stoR_CDX, // 門市代號
                  weeK_DAYS: item.weeK_DAYS, // 可送貨日
                  emlX_ADR: item.emlX_ADR, // Email,
                  preX_DAY: item.preX_DAY, // 可預下幾天單
                  limiT_TIME: item.limiT_TIME, // 結單時間
                  restdaY_SEND: item.restdaY_SEND, // 是否可下假日單
                  weB_URL: item.weB_URL // 教學影片
                };
              });
            } else {
              this.count = 0;
              this.commonCustomer = [];
            }
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 登出
    logout() {
      this.$router.push({ path: "login" });
      const rememberAccount = localStorage.REMEMBER_ACCOUNT;
      const account = localStorage.ACCOUNT;
      localStorage.clear();
      localStorage.setItem("REMEMBER_ACCOUNT", rememberAccount);
      sessionStorage.setItem('LOGOUT', true);
      if (rememberAccount === "true") {
        localStorage.setItem("ACCOUNT", account);
      }
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  watch: {
    customerType(newVal) {
      newVal === "common"
        ? (this.getCommonCustomer(), (this.page = 0))
        : (this.getUncommonCustomer(), (this.page = 0));
    }
  },

  created() {
    // 取得是否顯示客戶報價
    this.isQuoteUser = localStorage.IS_QUOTEUSER;
    this.getUncommonCustomer();
    localStorage.setItem("CART", JSON.stringify([]));
  }
};
</script>

<style lang="scss" scoped>
.customer-tab {
  a {
    background-color: rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 10px 20px;
    font-weight: 600;
    color: #909399;
    &.active {
      background-color: #fff;
    }
  }
}
.logout-btn {
  position: absolute;
  right: 0px;
  top: 30px;
}

/deep/ .el-table, .el-table__expanded-cell {
  font-weight: 600;
}
</style>
