import req from './https';


// 取得常用客戶列表
export const apiGetCommonCustomer = (params) => req('post', '/CustomerWeb/postSalNoxAndIsComm', params);

// 取得一般客戶列表
export const apiGetUncommonCustomer = (params) => req('post', '/CustomerWeb/postSalNoxAndNoComm', params);

// 將客戶加入常用
export const apiCustomerToCommon = (params) => req('post', '/Customer/postChangeToComm', params);

// 將客戶移除常用
export const apiCustomerToUncommon = (params) => req('post', '/Customer/postChangeToNoComm', params);

// 重新設定密碼
export const apiResetPassword = (params) => req('post', '/Customer/postResetPW', params);





